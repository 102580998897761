import React, {useState, createContext, useEffect} from 'react';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
	// Hold authenticated user data (user + accessToken)
	const [auth, setAuth] = useState(null);
	const [authAvatar, setAuthAvatar] = useState(null);
	const [colours, setColours] = useState({
		primary: '#000000',
		secondary: '#777777',
		tertiary: '#444444'
	});

	// Logout user
	const logout = () => {
		// Clear auth state
		setAuth(null);
	}

	// Login
	const login = (authData) => {
        // Set auth state
        setAuth(authData);
	}


    useEffect(() => {
        console.log("Auth has changed", auth);

		if(auth && auth.avatar)
			setAuthAvatar(auth.avatar);
    }, [auth]);

	return(
		<AuthContext.Provider value={{auth, authAvatar, login, logout, setAuthAvatar, colours, setColours}}>
			{props.children}
		</AuthContext.Provider>
	)
}