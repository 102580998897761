import React from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import {AuthProvider} from './contexts/AuthContext';
import ProtectedRoute from './components/routing/ProtectedRoute';
import Login from './screens/Login';
import Logout from './screens/Logout';
import Register from './screens/Register';
import Main from './screens/Main';

import './assets/css/style.css';
import './assets/css/style-sm.css';
import './assets/css/style-md.css';
import './assets/css/style-lg.css';

export default function App(){
    return (
        <AuthProvider>
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/register" component={Register}/>
                    <ProtectedRoute path="/" component={Main}/>
                </Switch>
            </BrowserRouter>
        </AuthProvider>
    )
};
