import React, {useContext, useState, useEffect} from 'react';
import { AuthContext } from '../contexts/AuthContext';import swal from 'sweetalert';
import Endpoint from '../components/Endpoint';

import '../assets/css/components/SidebarMyProfile.css';

export default function SidebarMyProfile({setStartChatVisible}){
    const {auth, authAvatar, setAuthAvatar, colours} = useContext(AuthContext);
    const [chooseAvatarVisible, setChooseAvatarVisible] = useState(false);
    const [avatars, setAvatars] = useState([]);

    // Fetch avatar list from server
    function loadAvatars(){
        // Get avatars
        Endpoint.getAvatars(auth)
        .then((response) => {
            if(!response)
                return false;

            // Failed?
            if(!response.success){
                throw new Error(response.errors.join("\n"));
            }

            // Set in state
            setAvatars(response.avatars);
        })
        // Catch errors
        .catch(error => {
            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    // Send avatar choice to server
    function saveAvatar(avatar){
        // Save it
        Endpoint.saveAvatar(auth, avatar)
        .then((response) => {
            if(!response)
                return false;

            // Failed?
            if(!response.success){
                throw new Error(response.errors.join("\n"));
            }

            // Hide the choose avatar panel
            setChooseAvatarVisible(false);

            // Update avatar
            setAuthAvatar(response.avatar.url);
        })
        // Catch errors
        .catch(error => {
            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    // On load
    useEffect(() => {
        // Got no avatars?
        if(!avatars.length)
            loadAvatars();
    }, []);

    return (
        <div className="my-profile">
            <img src={authAvatar} alt="" className="my-avatar" onClick={() => {
                setChooseAvatarVisible(!chooseAvatarVisible);
            }}/>

            {chooseAvatarVisible ? (
                <div className="avatar-list">
                    <div className="row no-gutters">
                        {avatars.map((avatar) => {
                            return (
                                <div className="col-6 col-md-4" key={avatar.uuid}>
                                    <div className="avatar">
                                        <img src={avatar.url} alt="" onClick={() => {
                                            saveAvatar(avatar.uuid);
                                        }}/>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : null}

            <button className="btn btn-primary" style={{backgroundColor: colours.tertiary}} onClick={() => {
                setStartChatVisible(true);
            }}>Start Chatting!</button>
        </div>
    )
}