import React, {useContext, useState, useEffect, Fragment} from 'react';
import Util from './Util';
import { AuthContext } from '../contexts/AuthContext';
import '../assets/css/components/BrowseTopics.css';

export default function BrowseTopics({sectors, askQuestion}){
    const {auth} = useContext(AuthContext);
    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        // Got any?
        if(sectors.length)
            setSelectedSector(sectors[0]);
    }, []);

    return (
        <div className="browse-topics">
            <div className="inside">
                <select className="form-control mb-4" value={selectedSector ? selectedSector.uuid : null} onChange={(e) => {
                    // Find the sector by uuid (because we're using the UUIDs as the option values)
                    let sector = sectors.find(s => {
                        return s.uuid === e.target.value;
                    });

                    if(typeof sector !== undefined)
                    {
                        setSelectedSector(sector);
                        setSelectedTopic(null);
                    }
                }}>
                    {sectors.map((sector) => (
                        <option key={sector.uuid} value={sector.uuid}>{sector.translations.find(t => t.language === auth.language.uuid).text}</option>
                    ))}
                </select>

                {selectedSector ? (
                    <Fragment>
                        {selectedSector.topics.length ? (
                            <Fragment>
                                {!selectedTopic ? (
                                    <div className="topics-grid">
                                        <div className="row">
                                            {selectedSector.topics.map((topic) => (
                                                <div className="col-4 col-md-4 col-lg-3 col-xl-2">
                                                    <div className="topic" onClick={() => {
                                                        setSelectedTopic(topic);
                                                    }}>
                                                        <img src={topic.image} className="thumb" alt=""/>
                                                        <span className="title">{topic.translations.find(t => t.language === auth.language.uuid).text}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <Fragment>
                                        <button class="btn btn-light mb-4" onClick={() => {
                                            setSelectedTopic(null);
                                        }}>Go Back</button>
                                        {selectedTopic.questions.length ? (
                                            <div className="questions-grid">
                                                {selectedTopic.questions.map((question) => (
                                                    <div className="question" onClick={() => {
                                                        askQuestion(question);
                                                    }}>
                                                        <span className="title">{question.translations.find(t => t.language === auth.language.uuid).text}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="text-center">
                                                No questions available in this topic!
                                            </p>
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        ) : (
                            <p className="text-center">
                                No topics available in this sector!
                            </p>
                        )}
                    </Fragment>
                ) : null}
            </div>
        </div>
    )
}