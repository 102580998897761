import React, {useContext, useState} from 'react';
import swal from 'sweetalert';
import Util from './Util';
import { AuthContext } from '../contexts/AuthContext';
import Endpoint from '../components/Endpoint';

import '../assets/css/components/StartChat.css';

export default function StartChat({selectConversation}){
    const {auth, colours} = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    function handleStartChat(e){
        e.preventDefault();

        setIsProcessing(true);

        // Start a conversation
        Endpoint.startChat(auth, username)
        .then((chatAttempt) => {
            setIsProcessing(false);

            if(!chatAttempt)
                return false;

            // Failed?
            if(!chatAttempt.success){
                throw new Error(chatAttempt.errors.join("\n"));
            }

            // Go to the conversation
            selectConversation(chatAttempt.conversation);
        })
        // Catch errors
        .catch(error => {
            setIsProcessing(false);

            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    return (
        <div className="start-chat d-flex justify-content-center align-items-center h-100">
            <div className="wrap">
                <div className="row">
                    <div className="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                        <div className="bg-white p-4">
                            <p><strong>Enter the username of who you'd like to start chatting with.</strong></p>

                            <form onSubmit={handleStartChat}>
                                <div className="form-group">
                                    <input type="text" name="username" className="form-control" id="login_username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                                </div>
                                <button className="btn btn-primary" style={{backgroundColor: colours.tertiary}} disabled={isProcessing}>{isProcessing ? 'Just a moment...' : 'Start'}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}