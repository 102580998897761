import React, {useState, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import { AuthContext } from '../contexts/AuthContext';
import Endpoint from '../components/Endpoint';

export default function Login(){
    const history = useHistory();
    const {login, setColours} = useContext(AuthContext);

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);



    const handleLoginSubmit = (e) => {
        e.preventDefault();

        setIsProcessing(true);

        // Try logging in
        Endpoint.login(username, password)
        .then((logonAttempt) => {
            setIsProcessing(false);

            if(!logonAttempt)
                return false;

            // Failed?
            if(!logonAttempt.success){
                throw new Error(logonAttempt.errors.join("\n"));
            }

            // Update colours
            setColours(logonAttempt.colours);

            // Login
            login(logonAttempt.user);

            // Redirect
            history.push('/');

        })
        // Catch errors
        .catch(error => {
            setIsProcessing(false);

            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    return (
        <div className="container d-flex justify-content-center align-items-center h-100">
            <div className="login_wrap">
                <h1>Please Login</h1>
                <form onSubmit={handleLoginSubmit}>
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" name="username" className="form-control" id="login_username" value={username} onChange={(e) => setUserName(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" name="password" className="form-control" id="login_password" value={password}  onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <button className="btn btn-primary" disabled={isProcessing}>{isProcessing ? 'Just a moment...' : 'Login'}</button>
                </form>
            </div>
        </div>
    )
}