const baseURL = "https://www.multichat.co.uk/api"

export default class Endpoint{

    static async login(username, password){
        // Build request body
        let requestBody = JSON.stringify({
            username: username,
            password: password
        });

        // Call endpoint
        return fetch(baseURL + '/users/login', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            //console.log(jsonResponse);

            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true,
                    user: jsonResponse.result.user,
                    colours: jsonResponse.result.colours
                }
            }
        });
    }

    static async getAvatars(auth){
        // Build request body
        let requestBody = JSON.stringify({
            key: auth.uuid
        });

        // Call endpoint
        return fetch(baseURL + '/users/list_avatars', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true,
                    avatars: jsonResponse.result.avatars
                }
            }
        });
    }

    static async saveAvatar(auth, avatar){
        // Build request body
        let requestBody = JSON.stringify({
            key: auth.uuid,
            user: auth.uuid,
            avatar: avatar
        });

        // Call endpoint
        return fetch(baseURL + '/users/save_avatar', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true,
                    avatar: jsonResponse.result.avatar
                }
            }
        });
    }

    static async getSectors(auth){
        // Build request body
        let requestBody = JSON.stringify({
            key: auth.uuid
        });

        // Call endpoint
        return fetch(baseURL + '/sectors/list', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            //console.log(jsonResponse);

            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true,
                    sectors: jsonResponse.result.sectors
                }
            }
        });
    }

    static async getConversations(auth){
        // Build request body
        let requestBody = JSON.stringify({
            key: auth.uuid
        });

        // Call endpoint
        return fetch(baseURL + '/conversations/list', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            //console.log(jsonResponse);

            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true,
                    conversations: jsonResponse.result.conversations
                }
            }
        });
    }

    static async startChat(auth, username){
        // Build request body
        let requestBody = JSON.stringify({
            key: auth.uuid,
            user: auth.uuid,
            username: username
        });

        // Call endpoint
        return fetch(baseURL + '/conversations/start', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true,
                    conversation: jsonResponse.result.conversation
                }
            }
        });
    }

    static async askQuestion(auth, conversation, question){
        // Build request body
        let requestBody = JSON.stringify({
            key: auth.uuid,
            user: auth.uuid,
            conversation: conversation.uuid,
            question: question.uuid
        });

        // Call endpoint
        return fetch(baseURL + '/conversations/ask_question', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true
                }
            }
        });
    }

    static async answerQuestion(auth, message, answer){
        // Build request body
        let requestBody = JSON.stringify({
            key: auth.uuid,
            user: auth.uuid,
            message: message.uuid,
            answer: answer.uuid
        });

        // Call endpoint
        return fetch(baseURL + '/conversations/answer_question', {
            method: 'POST',
            body: requestBody,
            headers: new Headers({'Content-Type': 'application/json; charset=utf-8'})
        })
        // Check for basic problems
        .then(response => {
            if(response.ok)
                return response;
            else
                throw Error("Unknown error!");
        })
        // Convert to JSON
        .then(response => response.json())
        // Handle it
        .then(jsonResponse => {
            // Error?
            if(jsonResponse.errors)
                throw new Error(jsonResponse.errors.join("\n"));
            else
            {
                return {
                    success: true
                }
            }
        });
    }
}
