import React, {Fragment, useContext} from 'react';
import Util from './Util';
import { AuthContext } from '../contexts/AuthContext';

import '../assets/css/components/ConversationList.css';

export default function ConversationList({conversations, selectConversation}){
	const {auth, colours} = useContext(AuthContext);

	// Got no conversations?
	if(!conversations.length)
	{
		return (
			<Fragment>
				<h4>Your Conversations</h4>
				<p className="no-conversations">
					You don't have any conversations yet! You should find someone to start a chat with!
				</p>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<h4>Your Conversations</h4>
			<ul className="conversation-list" style={{backgroundColor: colours.secondary}}>
				{conversations.map((conversation) => {
					// Grab the other user
					let otherUser = Util.getOtherConversationUser(auth, conversation);

					return (
						<li key={conversation.uuid} onClick={() => {
							selectConversation(conversation);
						}}>
							<img src={otherUser.avatar} alt="" className="avatar"/>
							<p>
								<span className="user">{otherUser.username}</span><br/>
								<span className="recent">{conversation.lastMessage && (conversation.lastMessage.answer ? conversation.lastMessage.answer.translations.find(t => t.language === otherUser.language.uuid).text : conversation.lastMessage.question.translations.find(t => t.language === otherUser.language.uuid).text)}</span>
							</p>
						</li>
					)
				})}
			</ul>
		</Fragment>
	)
}