import React, {Fragment, useContext, useState, useRef, useEffect} from 'react';
import Util from './Util';
import { AuthContext } from '../contexts/AuthContext';
import '../assets/css/components/Conversation.css';
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addDefaultLocale(en);

export default function Conversation({conversation, setStartChatVisible, answerQuestion}){
    const {auth, colours} = useContext(AuthContext);
    const [showTranslations, setShowTranslations] = useState(false);
    const bottomConversation = useRef(null);
    const [messageCount, setMessageCount] = useState(0);

    useEffect(() => {
        if(conversation && conversation.messages && messageCount !== conversation.messages.length)
            setMessageCount(conversation.messages.length);
    }, [conversation]);

    useEffect(() => {
        if(bottomConversation.current)
            bottomConversation.current.scrollIntoView({ behavior: 'smooth' });
    }, [messageCount]);

    // Got no conversation?
    if(!conversation)
    {
        return (
            <div className="conversation">
                <div className="not-selected">
                    <p>
                        Select a conversation from the side or start one from scratch.
                    </p>
                    <button className="btn btn-primary" style={{backgroundColor: colours.tertiary}} onClick={() => {
                        setStartChatVisible(true);
                    }}>Start Chatting!</button>
                </div>
            </div>
        )
    }

    // Got no messages?
    if(!conversation.messages.length)
    {
        return (
            <div className="conversation">
                <div className="empty-conversation">
                    <p>
                        Nothing has been said, yet!
                    </p>
                </div>
            </div>
        )
    }

    // Grab the other user
    const otherUser = Util.getOtherConversationUser(auth, conversation);

    return (
        <div className="conversation">
            <div className="col-lg-8 offset-lg-2">
                {conversation.messages.map((message) => {
                    let messageSide = (message.user.uuid === otherUser.uuid ? 'right' : 'left');
                    let answerSide = (message.user.uuid === otherUser.uuid ? 'left' : 'right');
                    let answerUser = (message.user.uuid === auth.uuid ? otherUser : auth);

                    return (
                        <Fragment>
                            <div key={message.uuid} className={`entry ${messageSide}`}>
                                <div className="meta" onClick={() => {
                                    setShowTranslations(!showTranslations);
                                }}>
                                    <strong>{message.user.username}</strong>, <span className="text-muted"><ReactTimeAgo date={message.created} locale="en-GB"/></span>
                                </div>
                                <div className="content">
                                    {otherUser.language.uuid !== auth.language.uuid && showTranslations ? (
                                        <p className="question-translated">
                                            {message.question.translations.find(t => t.language === otherUser.language.uuid).text}
                                        </p>
                                    ) : null}
                                    <p className="question">
                                        {message.question.translations.find(t => t.language === auth.language.uuid).text}
                                    </p>
                                </div>
                                {message.answer === null && message.user.uuid !== auth.uuid ? (
                                    <div className="choose-answer">
                                        <strong>Choose an Answer</strong>
                                        {message.question.answers.map((answer) => {
                                            return (
                                                <div className="answer" key={answer.uuid} onClick={() => {
                                                    answerQuestion(message, answer);
                                                }}>
                                                    {answer.translations.find(t => t.language === auth.language.uuid).text}
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}
                            </div>
                            {message.answer ? (
                                <div className={`entry ${answerSide}`}>
                                    <div className="meta" onClick={() => {
                                    setShowTranslations(!showTranslations);
                                }}>
                                        <strong>{answerUser.username}</strong>, <span className="text-muted"><ReactTimeAgo date={message.created} locale="en-GB"/></span>
                                    </div>
                                    <div className="content">
                                        {otherUser.language.uuid !== auth.language.uuid && showTranslations ? (
                                            <p className="question-translated">
                                                {message.answer.translations.find(t => t.language === otherUser.language.uuid).text}
                                            </p>
                                        ) : null}
                                        <p className="question">
                                            {message.answer.translations.find(t => t.language === auth.language.uuid).text}
                                        </p>
                                    </div>
                                </div>
                            ) : null}
                        </Fragment>
                    )
                })}
            </div>
            <div ref={bottomConversation}/>
        </div>
    )
}