import React, {useContext} from 'react';
import { Route, Redirect  } from 'react-router-dom';
import { AuthContext } from "../../contexts/AuthContext";

export default function ProtectedRoute({ component: Component, ...rest }) {
    // Extract values from context
    const {auth} = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={props =>
                auth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
      );
}