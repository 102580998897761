import React, {useState, useContext, useEffect, Fragment} from 'react';
//import {useHistory} from 'react-router-dom';
import swal from 'sweetalert';
import Endpoint from '../components/Endpoint';
import { AuthContext } from '../contexts/AuthContext';
import ConversationList from '../components/ConversationList';
import Conversation from '../components/Conversation';
import SidebarMyProfile from '../components/SidebarMyProfile';
import StartChat from '../components/StartChat';
import BrowseTopics from '../components/BrowseTopics';

export default function Main(){
    const {auth, logout, colours} = useContext(AuthContext);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [startChatVisible, setStartChatVisible] = useState(false);
    const [browseTopicsVisible, setBrowseTopicsVisible] = useState(false);
    const [activeConversation, setActiveConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [sectors, setSectors] = useState([]);

    const[refreshConversationInterval, setRefreshConversationInterval] = useState();

    // Go to server and grab all the conversations we're part of
    function refreshConversations(){
        // Refresh conversations
        Endpoint.getConversations(auth)
        .then((response) => {
            if(!response)
                return false;

            // Failed?
            if(!response.success){
                throw new Error(response.errors.join("\n"));
            }

            // Set in state
            setConversations(response.conversations);
        })
        // Catch errors
        .catch(error => {
            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    // Go to server and grab all the sectors (and child topics/questions)
    function refreshSectors(){
        // Fetch sectors
        Endpoint.getSectors(auth)
        .then((response) => {
            if(!response)
                return false;

            // Failed?
            if(!response.success){
                throw new Error(response.errors.join("\n"));
            }

            // Set in state
            setSectors(response.sectors);
        })
        // Catch errors
        .catch(error => {
            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    // Ask a question
    function askQuestion(question){
        // Make sure we've got an active conversation
        if(!activeConversation)
            return false;

        // Hide browse topics
        setBrowseTopicsVisible(false);

        // Ask the question
        Endpoint.askQuestion(auth, activeConversation, question)
        .then((response) => {
            if(!response)
                return false;

            // Failed?
            if(!response.success){
                throw new Error(response.errors.join("\n"));
            }

            // Refresh
            refreshConversations();
        })
        // Catch errors
        .catch(error => {
            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    // Answer a question
    function answerQuestion(message, answer){
        // Make sure we've got an active conversation
        if(!activeConversation)
            return false;

        // Answer the question
        Endpoint.answerQuestion(auth, message, answer)
        .then((response) => {
            if(!response)
                return false;

            // Failed?
            if(!response.success){
                throw new Error(response.errors.join("\n"));
            }

            // Refresh
            refreshConversations();
        })
        // Catch errors
        .catch(error => {
            swal({
                title: "There was a problem!",
                text: error.message
            });
        });
    }

    useEffect(() => {
        // Get sectors on load
        refreshSectors();

        // Refresh conversations on load
        refreshConversations();

        setRefreshConversationInterval(setInterval(() => {
            console.log("Running refresh conversation interval");
            refreshConversations();
        }, 2000));

        return function cleanup(){
            clearInterval(refreshConversationInterval);
        }
    }, []);

    // When conversations change
    useEffect(() => {
        // Got an active conversation?
        if(activeConversation)
        {
            // Find it in the conversations
            let conversationInArray = conversations.find((c) => {
                return c.uuid === activeConversation.uuid;
            });

            // Found it?
            if(typeof conversationInArray != undefined)
                setActiveConversation(conversationInArray);
        }
    }, [conversations, activeConversation]);

    return (
        <div className="container-fluid p-0 h-100">
            <div className="page-main">
                <button className={`btn btn-light d-md-none toggle-sidebar ${sidebarVisible === true ? '' : 'collapse'}`} onClick={() => {
                    setSidebarVisible(!sidebarVisible);
                }}><i className="fas fa-times"></i></button>

                <div className={`sidebar-cover ${sidebarVisible === true ? '' : 'collapse'}`}></div>

                <aside className={`sidebar ${sidebarVisible === true ? '' : 'collapse'}`} style={{backgroundColor: colours.primary}}>
                    <SidebarMyProfile setStartChatVisible={setStartChatVisible}/>
                    <ConversationList conversations={conversations} selectConversation={(conversation) => {
                        setActiveConversation(conversation);
                        setSidebarVisible(false);
                        setStartChatVisible(false);
                        setBrowseTopicsVisible(false);
                    }}/>
                </aside>

                <div className="content">
                    <div className="top-bar" style={{backgroundColor: colours.primary}}>
                        <button className="btn btn-light d-md-none" onClick={() => {
                            setSidebarVisible(!sidebarVisible);
                            setBrowseTopicsVisible(false);
                        }}><i className="fas fa-bars"></i></button>

                        <h1>Hello {auth.username}</h1>

                        <button className="btn btn-sm btn-light ml-auto" onClick={() => {
                            logout();
                        }}><i className="fas fa-times"></i> Logout</button>

                        <button className="btn btn-sm btn-info d-none" onClick={() => {
                            refreshConversations();
                        }}>Refresh</button>
                    </div>

                    {startChatVisible ? (
                        <StartChat selectConversation={(conversation) => {
                        setActiveConversation(conversation);
                        setSidebarVisible(false);
                        setStartChatVisible(false);
                        refreshConversations();
                    }}/>
                    ) : (
                        <Fragment>
                            <Conversation conversation={activeConversation} setStartChatVisible={setStartChatVisible} answerQuestion={answerQuestion}/>

                            {activeConversation ? (
                                <Fragment>
                                    {browseTopicsVisible ? <BrowseTopics sectors={sectors} askQuestion={askQuestion}/> : null}

                                    <div className="action-bar">
                                        <button className="btn btn-primary btn-lg w-100" style={{backgroundColor: colours.tertiary}} onClick={() => {
                                            // Toggle the browse topics component
                                            setBrowseTopicsVisible(!browseTopicsVisible);
                                        }}>{browseTopicsVisible ? 'Cancel' : 'Ask a Question'}</button>
                                    </div>
                                </Fragment>
                            ) : null}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}